import * as XLSX from 'xlsx';

export class IntradayResultsExcelHelper {

    /*
        Abbreviations
        -------------
        s -> start cell
        e -> end cell
        c -> column
        r -> row
        h -> hour
        v -> value

        Column Types
        ------------
        buyCapacity   -> shows buy capacity values
        sellCapacity  -> shows sell capacity values
        buyPrice      -> shows buy prices
        sellPrice     -> shows sell prices

    */

    constructor(file) {
        this.file = file;
    };

    excelAddresses = {
        "Energovia": {
            "BG": {
                "buyCapacity": { "s": { "c": 138, "r": 7 }, "e": { "c": 138, "r": 30 } },
                "sellCapacity": { "s": { "c": 139, "r": 7 }, "e": { "c": 139, "r": 30 } },
                "buyPrice": { "s": { "c": 140, "r": 7 }, "e": { "c": 140, "r": 30 } },
                "sellPrice": { "s": { "c": 141, "r": 7 }, "e": { "c": 141, "r": 30 } }
            },
            "RS": {
                "buyCapacity": { "s": { "c": 146, "r": 7 }, "e": { "c": 146, "r": 30 } },
                "sellCapacity": { "s": { "c": 147, "r": 7 }, "e": { "c": 147, "r": 30 } },
                "buyPrice": { "s": { "c": 148, "r": 7 }, "e": { "c": 148, "r": 30 } },
                "sellPrice": { "s": { "c": 149, "r": 7 }, "e": { "c": 149, "r": 30 } }
            },
            "HU": {
                "buyCapacity": { "s": { "c": 153, "r": 7 }, "e": { "c": 153, "r": 30 } },
                "sellCapacity": { "s": { "c": 154, "r": 7 }, "e": { "c": 154, "r": 30 } },
                "buyPrice": { "s": { "c": 155, "r": 7 }, "e": { "c": 155, "r": 30 } },
                "sellPrice": { "s": { "c": 156, "r": 7 }, "e": { "c": 156, "r": 30 } }
            },
            "HR": {
                "buyCapacity": { "s": { "c": 160, "r": 7 }, "e": { "c": 160, "r": 30 } },
                "sellCapacity": { "s": { "c": 161, "r": 7 }, "e": { "c": 161, "r": 30 } },
                "buyPrice": { "s": { "c": 162, "r": 7 }, "e": { "c": 162, "r": 30 } },
                "sellPrice": { "s": { "c": 163, "r": 7 }, "e": { "c": 163, "r": 30 } }
            },
            "RO": {
                "buyCapacity": { "s": { "c": 181, "r": 7 }, "e": { "c": 181, "r": 30 } },
                "sellCapacity": { "s": { "c": 182, "r": 7 }, "e": { "c": 182, "r": 30 } },
                "buyPrice": { "s": { "c": 183, "r": 7 }, "e": { "c": 183, "r": 30 } },
                "sellPrice": { "s": { "c": 184, "r": 7 }, "e": { "c": 184, "r": 30 } }
            }
        }
    };

    excelSheetNames = {
        "Energovia": "Power_exchanges",
        "Monolith Capital": "eZ-Ops-Deals"
    };

    zoneCountryDict = {
        "Swissgrid": "CH",
        "Amprion": "DE",
        "50 Hertz": "DE",
        "Tennet": "DE",
        "Transnet BW": "DE",
        "Elexon": "GB",
        "Tennet B.V.": "NL"

    }

    hourlyDataFormat = {
        "h": 0,
        "v": 0
    };

    loadExcel(selectedCounterParty, updateTableAfterSpecialFileImport) {

        try {

            let reader = new FileReader();
            let selectedAdresses = this.excelAddresses[selectedCounterParty];
            let results = {};

            reader.onload = (e) => {

                try {

                    const data = e.target.result;
                    const fetched = XLSX.read(data, { type: 'binary' });
                    const ws = fetched.Sheets[this.excelSheetNames[selectedCounterParty]];

                    Object.keys(selectedAdresses).forEach(country => {

                        results[country] = {};

                        Object.keys(selectedAdresses[country]).forEach(columnType => {

                            results[country][columnType] = [];

                            let range = selectedAdresses[country][columnType];
                            let hour = 0;

                            for (let R = range.s.r; R <= range.e.r; ++R) {
                                for (let C = range.s.c; C <= range.e.c; ++C) {
                                    let cell_address = { c: C, r: R };
                                    let data = XLSX.utils.encode_cell(cell_address);

                                    let hourly = Object.create(this.hourlyDataFormat)
                                    hourly.h = hour;

                                    if (ws[data] && ws[data].v) {
                                        if (columnType === "buyCapacity" || columnType === "sellCapacity")
                                            hourly.v = parseFloat(ws[data].v).toFixed(1);
                                        else
                                            hourly.v = parseFloat(ws[data].v).toFixed(2);
                                    } else {
                                        hourly.v = 0;
                                    }

                                    results[country][columnType].push(hourly);

                                    hour++;
                                }
                            }

                        });

                    });

                    updateTableAfterSpecialFileImport(results);

                } catch (error) {
                    updateTableAfterSpecialFileImport({});
                }

            };

            reader.readAsBinaryString(this.file);

        } catch (error) {
            updateTableAfterSpecialFileImport({});
        }

    }

    loadMonolithExcel(selectedCounterParty, updateTableAfterMonolithSpecialFileImport){
        try {

            let reader = new FileReader();
            let intradayResults = [];
            let countries = ['DE', 'NL', 'CH', 'GB'];
            let operationTypes = ["BUY", "SELL"];
            let countryResults = {};

            reader.onload = (e) => {

                try {
                    
                    countries.forEach(country => {
                        countryResults[country] = {};
                        operationTypes.forEach(operationType => {
                            countryResults[country][operationType] = {};
                        })
                    })

                    const data = e.target.result;
                    const fetched = XLSX.read(data, { type: 'binary' });
                    let excelRows = XLSX.utils.sheet_to_json(fetched.Sheets[this.excelSheetNames[selectedCounterParty]])
                    excelRows.forEach(r => {
                        let country = this.zoneCountryDict[r['Delivery zone']]
                        let operationType = r['Direction']
                        let startDate = r['Start date']
                        let startHour = (new Date(startDate)).getHours()
                        let endDate = r['End date']
                        let volume = Number(r['Fixed volume'].toString().replace(",", "."))
                        let price = Number(r['Fixed price'].toString().replace(",", "."))
                        let pnl = volume * price * (r['Direction'] === 'BUY' ? -1 : 1)
                        let countryOpHour = countryResults[country][operationType][startHour];
                        if (! countryOpHour){
                            countryResults[country][operationType][startHour] = {"PNL": pnl, "Volume": volume, "Price": price};
                        } 
                        else{
                            let newPnl = countryResults[country][operationType][startHour]["PNL"] + pnl;
                            let newVolume = countryResults[country][operationType][startHour]["Volume"] + volume;
                            let newPrice = (newPnl / newVolume > 0) ? (newPnl / newVolume) : (newPnl / newVolume * -1);

                            countryResults[country][operationType][startHour]["PNL"] = newPnl;
                            countryResults[country][operationType][startHour]["Volume"] = newVolume;
                            countryResults[country][operationType][startHour]["Price"] = newPrice.toFixed(4);
                        }

                        intradayResults.push([country, operationType, startDate, endDate, volume, price, pnl.toFixed(2)]);                      
                    })
                    updateTableAfterMonolithSpecialFileImport(intradayResults, countryResults);

                } catch (error) {
                    updateTableAfterMonolithSpecialFileImport([], {});
                }
            };
            reader.readAsBinaryString(this.file);
        } catch (error) {
            updateTableAfterMonolithSpecialFileImport([], {});
        }

    }

};