import React from "react";
import TContentContainer from "../../components/TContentContainer/TContentContainer";
import TContentDetailContainer from "../../components/TContentDetailContainer/TContentDetailContainer";
import LeftSideContainer from "../../components/TTPageContainers/LeftSideContainer";
import InvoiceSummaryFilter from './InvoiceSummaryFilter';
import { getCompaniesDetail } from "../../api/services";
import { handleApiError, alertError, specifyErrorMessage } from "../../utils/errorHandler";
import { SpinnerManager } from "../../components/TSpinner/SpinnerManager";
import history from "../../../history";
import DataGrid from 'react-data-grid';
import FileSaver from 'file-saver';
import { getTSO, getInvoiceSummary, getInvoiceSummaryFile } from '../../../apis/vitusApi';

import './InvoiceSummary.css';

const EXCLUDED_TSO = ["TEIAS", "ESO"];
const VITUS = "Vitus"

class InvoiceSummary extends React.Component {
    title = "Invoice Summary";

    spinner = new SpinnerManager(history.location.pathname);

    state = {
        counterPartyNames: [],
        companyNames: [],
        marketNames: [],
        bookNames: [],
        tsoNames: [],
        directionNames: [],
        filter: {},
        invoiceSummaryColumns: [],
        invoiceSummaryRows: [],
        monthlyColumns: [],
        monthlyInvoiceSummaryDetails: []
    };

    componentDidMount() {
        this.getCompaniesDetail();
        this.getTsoDetails();
    }

    startSpinner(key) {
        return this.spinner.showSpinner(key);
    }

    stopSpinner(key) {
        this.spinner.hideSpinner(key);
    }

    onShowButtonClick = async (filter) => {

        const spinnerKey = this.startSpinner("invoiceSummary");

        this.setState({ filter: filter });

        getInvoiceSummary(filter)
            .then((response) => {
                if (response.data.success) {
                    this.setState({
                        monthlyColumns: response.data.success.monthly_columns,
                        monthlyInvoiceSummaryDetails: response.data.success.monthly_invoice_summary_details,
                    });
                }
                else {
                    this.setState({
                        monthlyColumns: [],
                        monthlyInvoiceSummaryDetails: [],
                    });
                    this.showErrorMessage(response.data.error)
                }

            },(error) => {
                handleApiError(error);
            })
            .finally(() => {
                this.prepareInvoiceSummaryResult();
                this.stopSpinner(spinnerKey);
            });

    }

    showErrorMessage(error) {
        const { message } = specifyErrorMessage(error, this.errorMessages);

        if (message)
            alertError(message);
    }

    prepareInvoiceSummaryResult() {

        if (this.state.monthlyColumns.length > 0 || this.state.monthlyInvoiceSummaryDetails) {

            let counterPartyColumn = {
                key: "Counterparty",
                name: "Counterparty",
                width: 250,
                height: 150,
                formatter({ row }) {
                    if (row["Subtotal"]) {
                        return <div className="bold-subtotal-text">{row["Counterparty"]}</div>
                    }
                    if (row["Direction"] === "Payment") {
                        return <div className="bold-payment-text">{row["Counterparty"]}</div>
                    }
                    if (row["Direction"].includes("Total")) {
                        return <div className="bold-total-text">{row["Counterparty"]}</div>
                    }
                    return <div className="bold-text">{row["Counterparty"]}</div>
                }
            }

            let directionColumn = {
                key: "Direction",
                name: "Direction",
                width: 150,
                height: 150,
                formatter({ row }) {
                    if (row["Subtotal"]) {
                        return <div className="bold-subtotal-text">{row["Direction"]}</div>
                    }
                    if (row["Direction"] === "Payment") {
                        return <div className="payment-text">{row["Direction"]}</div>
                    }
                    if (row["Direction"].includes("Total")) {
                        return <div className="bold-total-text">{row["Direction"]}</div>
                    }
                    return row["Direction"];
                }
            }

            let currencyColumn = {
                key: "Currency",
                name: "Currency",
                width: 150,
                height: 150,
                formatter({ row }) {
                    if (row["Subtotal"]) {
                        return <div className="bold-subtotal-text">{row["Currency"]}</div>
                    }
                    if (row["Direction"] === "Payment") {
                        return <div className="payment-text">{row["Currency"]}</div>
                    }
                    if (row["Direction"].includes("Total")) {
                        return <div className="bold-total-text">{row["Currency"]}</div>
                    }
                    return row["Currency"];
                }
            }

            let monthColumns = []
            this.state.monthlyColumns.forEach((month) => {

                monthColumns.push({
                    key: month,
                    name: month,
                    width: 150,
                    height: 150,
                    formatter({ row }) {

                        const month_value = row[month] ? row[month] : 0;
                        if (row["Subtotal"]) {
                            if (row[month] < 0) {
                                return <div className="bold-subtotal-negative-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                            else {
                                return <div className="bold-subtotal-positive-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                        }
                        if (row["Direction"].includes("Total")) {
                            if (row[month] < 0) {
                                return <div className="bold-negative-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                            else {
                                return <div className="bold-positive-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                        }
                        if (row["Direction"].includes("Payment")) {
                            return <div className="payment">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                        }
                        if (!row["Subtotal"] && !row["Direction"].includes("Total") && !row["Direction"].includes("Payment")) {
                            if (row[month] < 0) {
                                return <div className="negative-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                            else {
                                return <div className="positive-summary">{month_value.toLocaleString(undefined, { maximumFractionDigits: 2 })}</div>;
                            }
                        }
                    }
                })
            })

            let rowList = this.state.monthlyInvoiceSummaryDetails.map((invoiceSummary) => {
                let value_list = {
                    "Counterparty": invoiceSummary["Counterparty"],
                    "Direction": invoiceSummary["Direction"],
                    "Currency": invoiceSummary["Currency"],
                    "Subtotal": invoiceSummary["is_subtotal"] ? true : false
                }

                this.state.monthlyColumns.forEach((month) => {
                    value_list[month] = invoiceSummary[month];
                })

                return value_list;
            })

            this.setState({
                invoiceSummaryColumns: [counterPartyColumn, directionColumn, currencyColumn, ...monthColumns],
                invoiceSummaryRows: [...rowList]
            })

        }

    }

    downloadInvoiceSummary = async () => {
        const spinnerKey = this.startSpinner("downloadInvoiceSummary");

        let body = {
            monthly_data: { monthlyColumns: this.state.monthlyColumns, monthlyInvoiceSummaryDetails: this.state.monthlyInvoiceSummaryDetails },
        }

        getInvoiceSummaryFile(body).then(async response => {

            const filename = `Invoice Summary Report.xlsx`;
            const contentType = response.headers["content-type"];

            const blob = new Blob([response.data], { type: contentType });

            if (contentType === "application/json") {
                //const error = blob.text();
                return;
            }

            FileSaver.saveAs(blob, filename);
            return;
        }, error => {
            handleApiError(error);
        }).finally(() => {
            this.stopSpinner(spinnerKey);
        });
    }

    async getCompaniesDetail() {
        const spinnerKey = this.startSpinner("getCompaniesDetail");
        getCompaniesDetail()
            .then(
                (response) => {
                    if (response.data.success) {
                        let successData = response.data.success;
                        this.setState({
                            companyNames: successData.company_list.filter(company => company.type === VITUS).map((company) => ({
                                value: company.name,
                                label: company.name
                            }))
                        });
                    }
                    else {
                        this.setState({ companyNames: [] });
                        this.showErrorMessage(response.data.error);
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.stopSpinner(spinnerKey)
            });
    }

    async getTsoDetails() {
        const spinnerKey = this.startSpinner("getTSODetails");
        getTSO()
            .then(
                (response) => {
                    if (response.data.success) {
                        let counter_party_details = response.data.success.tso_list;

                        const counter_party_set = new Set();
                        const direction_set = new Set();
                        const tso_set = new Set();
                        counter_party_details.forEach(counter_party => {
                            counter_party_set.add(counter_party["counter_party"]);
                            counter_party.directions.forEach(direction => {
                                if (!EXCLUDED_TSO.includes(direction["tso"])) {
                                    direction_set.add(direction["direction"]);
                                    tso_set.add(direction["tso"]);
                                }
                            })
                        });

                        this.setState({
                            tsoNames: Array.from(tso_set).sort().map((tso) => ({
                                value: tso,
                                label: tso,
                            })),
                            directionNames: Array.from(direction_set).sort().map((direction) => ({
                                value: direction,
                                label: direction
                            })),
                            counterPartyNames: Array.from(counter_party_set).sort().map((counter_party) => ({
                                value: counter_party,
                                label: counter_party
                            }))
                        });
                    }
                    else {
                        this.setState({ tsoNames: [], directionNames: [], counterPartyNames: [] });
                        this.showErrorMessage(response.data.error);
                    }
                },
                (error) => {
                    handleApiError(error);
                }
            )
            .finally(() => {
                this.stopSpinner(spinnerKey);
            });
    }

    getTableComponent() {
        return (
            <LeftSideContainer
                contentLabel={this.title}
                contentDefaultExpanded={true}
                contentComponent={
                    <div>
                        <div className="t-table-toolbar">
                            <button type="button" className="btn t-orange-button" onClick={() => { this.downloadInvoiceSummary() }}>
                                Export to XLSX
                            </button>
                        </div>
                        {
                            this.state.invoiceSummaryRows.length ? (
                                <DataGrid
                                    columns={this.state.invoiceSummaryColumns}
                                    rows={this.state.invoiceSummaryRows.filter(s => s)}
                                    defaultColumnOptions={{ resizable: true }}
                                    style={{ height: `${(this.state.invoiceSummaryRows.length + 1) * 50}px` }}
                                />
                            ) : null
                        }
                    </div>
                }
                filterComponent={
                    this.state.tsoNames.length &&
                        this.state.directionNames.length &&
                        this.state.companyNames.length &&
                        this.state.counterPartyNames.length ? (
                        <InvoiceSummaryFilter
                            counterPartyNames={this.state.counterPartyNames}
                            companyNames={this.state.companyNames}
                            tsoNames={this.state.tsoNames}
                            directionNames={this.state.directionNames}
                            onShowButtonClick={this.onShowButtonClick}
                        ></InvoiceSummaryFilter>
                    ) : null
                }
            ></LeftSideContainer>
        );
    }

    render() {
        return (
            <TContentContainer>
                <TContentDetailContainer
                    leftSideComponent={this.getTableComponent()}
                ></TContentDetailContainer>
            </TContentContainer>
        );
    }
}

export default InvoiceSummary;
