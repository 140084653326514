import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import HomeIcon from "@material-ui/icons/Home";
import ReceiptIcon from "@material-ui/icons/Receipt";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import ShopIcon from "@material-ui/icons/Shop";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import BusinessIcon from "@material-ui/icons/Business";
import LocalAtmIcon from "@material-ui/icons/LocalAtm";
import ExposureIcon from "@material-ui/icons/Exposure";
import BookIcon from '@material-ui/icons/Book';
import NoteIcon from '@material-ui/icons/Note';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import BlurOnIcon from '@material-ui/icons/BlurOn';
import TestEtrmPage2 from "../components/test/TestEtrmPage2";
import Market from "../pages/Market/Market";
import BookMain from "../pages/Book/BookMain";
import Company from "../pages/Company/Company";
import Period from "../pages/Period/Period";
import PriceCurveMain from "../pages/PriceCurve/PriceCurveMain";
import CostMain from "../pages/Cost/CostMain";
import Contract from "../pages/Contract/Contract";
import BackOffice from "../pages/BackOffice/BackOffice";
import Document from "../pages/Document/Document";
import Collateral from "../pages/Collateral/Collateral";
import Pnl from "../pages/Pnl/Pnl";
import PnlV2 from "../pages/Pnl/PnlV2";
import TotalPnl from "../pages/TotalPnl/TotalPnl";
import Billing from "../pages/Billing/Billing";
import ContractPnl from "../pages/ContractPnl/ContractPnl";
import Position from "../pages/PositionReporting/Position";
import PosAndMark from "../pages/PosAndMark/PosAndMark";
import Distribution from "../pages/Distribution/Distribution";
import DailyLiquidation from "../pages/DailyLiquidation/DailyLiquidation";
import ApiContract from "../pages/ApiContract/AutomatedRecordedContracts";
import ResaleRevenue from "../pages/ResaleRevenue/ResaleRevenue";
import InvoiceSummary from "../pages/InvoiceSummary/InvoiceSummary";


export const etrmSubRoute = "/etrm";

export const sidebarRoutes = [
  /*{
		path: `${etrmSubRoute}/admin`,
		component: Administration,
		name: 'administration',
		label: 'Administration',
		Icon: SettingsIcon,
		isSideItem: true,
		routes: [
			{
				path: `${etrmSubRoute}/trader`,
				component: TestEtrmPage2,
				name: 'trader',
				label: 'Add Trader',
				Icon: PersonAddIcon,
			},
			{
				path: `${etrmSubRoute}/group`,
				component: TestEtrmPage2,
				name: 'group',
				label: 'Add Group',
				Icon: GroupAddIcon,
			}
		],
	},*/
  /*{
    path: `${etrmSubRoute}/front-office`,
    component: TestEtrmPage2,
    name: "frontOffice",
    label: "Front Office",
    Icon: DesktopWindowsIcon,
  },
  {
    path: `${etrmSubRoute}/middle-office`,
    component: TestEtrmPage2,
    name: "middleOffice",
    label: "Middle Office",
    Icon: DesktopWindowsIcon,
  },*/
  {
    path: `${etrmSubRoute}/back-office`,
    component: BackOffice,
    name: "backOffice",
    label: "Back Office",
    Icon: HomeIcon,
    routes: [
      {
        path: `${etrmSubRoute}/companies`,
        component: Company,
        name: "companies",
        label: "Companies",
        Icon: BusinessIcon,
      },
      {
        path: `${etrmSubRoute}/documents`,
        component: Document,
        name: "documents",
        label: "Documents",
        Icon: InsertDriveFileIcon,
      },
      {
        path: `${etrmSubRoute}/costs`,
        component: CostMain,
        name: "costs",
        label: "Costs",
        Icon: LocalAtmIcon,
      },
      {
        path: `${etrmSubRoute}/books`,
        component: BookMain,
        name: "books",
        label: "Books",
        Icon: BookIcon,
      },
      {
        path: `${etrmSubRoute}/contracts`,
        component: Contract,
        name: "contracts",
        label: "Contracts",
        Icon: LibraryBooksIcon,
      },
      {
        path: `${etrmSubRoute}/auto_recorded_contracts`,
        component: ApiContract,
        name: "auto_recorded_contracts",
        label: "Auto Recorded Contracts",
        Icon: LibraryBooksIcon,
      },
      {
        path: `${etrmSubRoute}/collaterals`,
        component: Collateral,
        name: "collateral",
        label: "Collaterals",
        Icon: ExposureIcon,
      },
      /*{
				path: `${etrmSubRoute}/contract-types`,
				component: ContractType,
				name: 'contractTypes',
				label: 'Contract Types',
				Icon: PolicyIcon,
			},*/
      {
        path: `${etrmSubRoute}/markets`,
        component: Market,
        name: "markets",
        label: "Markets",
        Icon: ShopIcon,
      },
      {
        path: `${etrmSubRoute}/price-curves`,
        component: PriceCurveMain,
        name: "prices",
        label: "Price Curves",
        Icon: AttachMoneyIcon,
      },
      {
        path: `${etrmSubRoute}/periods`,
        component: Period,
        name: "periods",
        label: "Periods",
        Icon: AccessTimeIcon,
      },
      /*{
        path: `${etrmSubRoute}/pnl`,
        component: Pnl,
        name: "pnl",
        label: "Profit and Loss",
        Icon: AccountBalanceWalletIcon,
      },*/
      {
        path: `${etrmSubRoute}/pnl`,
        component: PnlV2,
        name: "pnl",
        label: "Profit and Loss",
        Icon: AccountBalanceWalletIcon,
      },
      {
        path: `${etrmSubRoute}/totalPnl`,
        component: TotalPnl,
        name: "total_pnl",
        label: "Total Profit and Loss",
        Icon: AccountBalanceWalletIcon,
      },
      {
        path: `${etrmSubRoute}/billing`,
        component: Billing,
        name: "billing",
        label: "Billing",
        Icon: ReceiptIcon,
      },
      {
        path: `${etrmSubRoute}/contract_pnl`,
        component: ContractPnl,
        name: "contract_pnl",
        label: "Contract P&L",
        Icon: NoteIcon,
      },
      {
        path: `${etrmSubRoute}/positions`,
        component: Position,
        name: "position",
        label: "Position Reporting",
        Icon: AssessmentIcon,
      },
      {
        path: `${etrmSubRoute}/pos_and_mark`,
        component: PosAndMark,
        name: "pos_and_mark",
        label: "Pos and Mark",
        Icon: AssessmentIcon,
      },
      {
        path: `${etrmSubRoute}/distribution`,
        component: Distribution,
        name: "distribution",
        label: "Distribution",
        Icon: BlurOnIcon,
      },
      {
        path: `${etrmSubRoute}/daily_liquidation`,
        component: DailyLiquidation,
        name: "daily_liquidation",
        label: "Daily Liquidation",
        Icon: AssessmentIcon,
      },
      {
        path: `${etrmSubRoute}/resale_revenue`,
        component: ResaleRevenue,
        name: "resale_revenue",
        label: "Resale Revenue",
        Icon: AccountBalanceWalletIcon,
      },
      {
        path: `${etrmSubRoute}/invoice_summary`,
        component: InvoiceSummary,
        name: "invoice_summary",
        label: "Invoice Summary",
        Icon: AccountBalanceWalletIcon,
      }
    ],
  },
];

export const etrmRoutes = [
  {
    path: `${etrmSubRoute}`,
    component: BackOffice,
    routes: sidebarRoutes,
  },
];
